.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px; 
  gap:10%/* Adjust margin as needed */
}

.card-container .card {
  width: 300px; /* Adjust width and margin to fit 2 cards in a row */
  margin: 10px 10px; /* Adjust margin as needed */
  margin-bottom: 20px; /* Adjust margin as needed */
  height: 200px; /* Adjust height as needed */
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--containerColor);
}
.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:30px;
  
}

.card-content h3 {
  font-size: 24px;
  color:white; /* Adjust color as needed */
}

.small-button {
  width: 100px; /* Adjust button width as needed */
  padding: 8px; /* Adjust button padding as needed */
  font-size: 12px; /* Adjust button font size as needed */
  background:  #0668CB;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;

}
.bt{
  margin-bottom: 10px;
}
