.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--backgroundcolor);

top: 0;
  color: #fff;
z-index: 1000;
}

.logo img {
  height: 40px; /* Adjust the height of your logo */
}

.menu-toggle {
  display: none; /* Hide the toggle button by default */
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
}

.menu {
  display: flex;
  list-style: none;
  
}

.menu li {
  padding: 0 150px 0 150px;
  font-size: 18px;
  font-family:  "League Spartan", sans-serif;
}
.menu a:hover{
  color: rgb(30, 200, 212)
}

.menu a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .navbar{
    background-color: var(--containerColor);
  }
  .menu {
    display: none; /* Hide the menu by default on small screens */
    flex-direction: column;
    background-color:var(--containerColor);
    padding: 20px;
    position: absolute;
    top: 60px; /* Adjust the top position as needed */
    left: 0;
    width: 100%;
   margin: 0;
    
  }

  .menu.open {
    display: flex;
text-align: center;
    z-index: 999;
    gap: 10px; /* Show the menu when open */
    opacity: 0.8;
  
  }

  .menu-toggle {
    display: block; /* Show the toggle button on small screens */
  }
}
