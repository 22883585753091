.countdown .content {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: auto;
	gap: 12px;
    background-color: transparent;
}
.countdown h2 {
	margin-bottom: 20px;
	font-size: 30px;
}
.colon{
	height: 24px;
	margin: auto;
}
.countdown{
 height: 300px;

}
.countdown .label{
    font-size: 6px;
}
.countdown .box {
	width: calc(100% / 4);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 70px;
	max-width: 70px;
}

.countdown .value {
	font-size: 30px;
	font-weight: 600;
	background-color: var(--backgroundColor);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	width: 80px;
	height: 80px;
	position: relative;
	box-shadow: -2px 0px 5px 1px #232423ad;
}

@media screen and (min-width: 550px) {
	.countdown .content {
		gap: 30px;
	}
	.countdown .value {
		font-size: 50px;
		width: 120px;
		height: 120px;
	}
	.countdown .box {
		height: 200px;
		max-width: 200px;
	}
}

@media screen and (min-width: 790px) {
	.countdown .value {
		font-size: 50px;
		height: 100px;
		width: 100px;
	}
	.countdown .box {
		max-width:100px;
		height: 150px;
	}
}

.countdown .value::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	z-index: 2;
	background-color:var(--backgroundColor);
	box-shadow: 0px 0px 5px 0px #2e2f2ed6;
}
.countdown .value::after {
	content: "";
	position: absolute;
	z-index: 2;
	left: -7px;
	width: 10px;
	height: 30px;
	background-color:var(--backgroundColor);
	box-shadow: inset -1px 1px 5px 1px #0e0e0ead;
    
}

.countdown .label {
	font-size: 20px;
	font-weight: 100;
	letter-spacing: 4px;
	margin-top: 10px;

}
@media screen and (max-width: 576px){
.countdown .label{
    font-size: 8px;
    overflow: hidden;
}
}
@media screen and (min-height: 500px) and (max-height: 700px){
	.countdown{
		height: 200px;
	   overflow: hidden;
	   }
}