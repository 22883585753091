* {
    box-sizing: border-box;
  }
  
  
  #time{
    background-color: #5f85d9;
    font-family: Helvetica, sans-serif;
  }
  
 .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 50px;
   
    
  }
 .head{
  text-align: center;
  margin-bottom: 50px;
 } 
  
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color:#CCCCCC;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  
  .con {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  .con h2{
    font-size: 24px;
  }
  .con p {
    font-size: 20px;
  }
  .con::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid var(--primaryColor);
    top: 15px;
    border-radius: 50%;
    z-index: 999;
  }
  
  
  .left {
    left: 0;
  }
  
  
  .right {
    left: 50%;
  }
  
  
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 2;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #0f151c;
  }
  
  
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 2;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent #0F151C transparent transparent;
  }
  
  
  .right::after {
    left: -16px;
  }
  
  
  .content {
    padding:  30px;
    background-color: #0c1d3e;
    position: relative;
    border-radius: 8px;
  }
  
  
  @media screen and (max-width: 600px) {
  
    .timeline::after {
      left: 31px;
    }
  
 
    .con {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
  
    .con::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
      z-index: 1;
    }
  

    .left::after, .right::after {
      left: 15px;
    }
  .content h2{
    font-size: 20px;
  }
  .content p{
    font-size: 16px;
  }

    .right {
      left: 0%;
    }
  }
  